.MatchInfo {
    width: 1100px;
    background-color: #2e2755;
    border-radius: 5px;
    box-shadow: 0px 0px 4px #ffffff5e;
    display: flex;
    flex-direction: column;

    .Header {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #5e45c3;

        .Name{
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 15px;

            .dot{
                width: 5px;
                height: 5px;
                background-color: #fff;
                border-radius: 50%;
            }
        }
    }

    .Match-Board {
        padding: 20px;

        .Right{
            flex-direction: row-reverse;
            .Game-Name{
                text-align: end;
            }
        }

        .Red {
            color: #b3453b;
        }

        .Blue {
            color: #5383e7;
        }

        .Sumary-Row{
            display: flex;
            justify-content: space-between;
            .Sumary {
                width: 500px;
                display: flex;
                align-items: center;
                gap: 50px;
                font-weight: 700;

                .KDA {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .kda-icon {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 24px;
                    }
                }

                .Winner{
                    padding: 5px 10px;
                    border-radius: 100px;
                    background-color: #fc7c00;
                    color: #fff;
                }
            }
        }

        .Table-Player{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            
            .Row-Player{
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 20px 0;

                &:hover{
                    background-color: #3c3269;
                }
                .Player {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 500px;

                    .Champ {
                        width: 50px;
                        height: 50px;

                        img {
                            width: 100%;
                            aspect-ratio: 1/1;
                        }
                    }

                    .Spells {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        gap: 5px;

                        .Group {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 5px;

                            .Spell {
                                width: 20px;
                                height: 20px;

                                img {
                                    width: 100%;
                                    aspect-ratio: 1/1;
                                }
                            }

                            .Rune{
                                max-width: 30px;
                                img{
                                    width: 100%;
                                    border-radius: 50%;
                                }
                            }
                        }


                    }

                    .Group-Info{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        
                        .Game-Name{
                            color: #fff;
                            text-decoration: none;

                            &:hover{
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        .List-Item{
                            display: flex;
                            align-items: center;
                            gap: 2px;
                            .Item{
                                width: 20px;
                                height: 20px;
                                border: 1px solid #888;
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                    
                    .Player-Number{
                        width: 20%;
                        font-size: 14px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;
                        .KDA{
                            font-weight: 700;
                        }
                        .Rate{
                            color: #966def;
                        }
                    }

                    .Player-CS{
                        text-align: center;
                        width: 15%;
                        .CS{
                            color:#60b1db;
                        }
                        .Gold{
                            font-size: 12px;
                            color: #e8d93f;
                        }
                    }
                }

                .Lane-Icon{
                    width: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}