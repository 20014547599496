.App {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Waiting{
    width: 100%;
    height: 100%;
    background-color: #aaaaaaa8;
    box-shadow: 0 0 2px #aaa;
    border-radius: 2px;
}