// LoadingSpinner.scss

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-top: 4px solid #318ab7;
        border-radius: 50%;
        aspect-ratio: 1/1;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}