.History {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .Title{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;
        h2 {
            padding: 5px;
            color:#8890b5;
            border-bottom: 3px solid #8890b5;
        }
    }

    .History-Card {
        width: 1000px;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 1px 1px 1px #ffffff37;
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        

        &.win {
            background-color: #3f4ba3a2;

            &:hover{
                background-color: #0f185cc5;
                box-shadow: 0px 0px 2px #ffffff37;
            }
        }

        &.lose {
            background-color: #88332fb0;
            &:hover{
                background-color: #6f18138e;
            }
        }

        .Champion {
            width: 15%;
            display: flex;
            align-items: center;
            .Image {
                img {
                    width: 75px;
                }

                .Level {
                    border: 2px solid #a47d2f;
                    border-radius: 50%;
                    bottom: 0;
                    left: 60px;
                    width: 25px;
                    height: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    background-color: #242249;
                }
            }
        }

        .Match-Info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .Duration {
                font-size: 14px;
            }

            .Spells {
                display: flex;
                gap: 10px;

                .Spell {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 100%;
                    }
                }
            }
        }

        .Items-Info {
            width: min-content;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
            .Items {
                display: flex;
                gap: 3px;
                margin-bottom: 3px;

                .Item {
                    width: 40px;
                    height: 40px;
                    border: 1px solid #999;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .Numbers {
                display: flex;
                justify-content: space-between;

                .KDA{
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }

                .CS{
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }

                .BUDGET{
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    img{
                        width: 24px;
                    }
                }
            }
        }

        .Match-Date {
            width: 30%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .More-History{
        padding: 20px;
        color: #fff;
        font-size:18px;
        &:hover{
            text-decoration: underline;
            cursor: pointer;
        }
    }
}