.Rank {
    margin-top: 20px;
    display: flex;
    border: 2px solid #302574;
    justify-content: space-evenly;

    .Rank-Info {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;

        .Rank-Select {
            width: max-content;

            &:hover .Select{
                display: block;
            }

            .Value{
                border: 1px solid #8890b5;
                border-radius: 3px;
                color: #8890b5;
                display: flex;
                gap: 10px;
                font-size: 13px;
                align-items: center;
                padding: 5px 10px;
                background-color: #211847;
            }

            .Select{
                display: none;
                z-index: 100;
                position: absolute;
                width: 100%;
                box-shadow: 0px 0px 2px #8890b5;

                

                .Option {
                    color: #8890b5;
                    display: flex;
                    gap: 10px;
                    font-size: 13px;
                    align-items: center;
                    padding: 5px 10px;
                    background-color: #211847;

                    &.active{
                        background-color: #2f1990;
                    }
    
                    &:hover{
                        cursor: pointer;
                        background-color: #503ea0;
                    }
                }
            }
        }

        .Rank-Title {
            padding: 0 3px;
            .Rank-Name {
                font-weight: 700;
            }

            .Rank-Score {
                font-size: 12px;
            }
        }
    }


    .Rank-Icon {
        display: flex;
        gap: 20px;

        img {
            width: 120px;
        }
    }

    .No-Rank{
        padding: 20px;
    }
}