.ProfilePage {
    width: 1000px;
    padding: 20px;
    color: #fff;

    .Row {
        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .Left{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .Container {
        padding: 10px 20px;
        background-color: #2e2755;
        box-shadow: 1px 1px 4px #ffffff90;
        border-radius: 5px;
    }

    .Info {
        padding: 20px;
        width: 360px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .Avatar-Icon {
            width: 75px;
            height: 75px;
            aspect-ratio: 1/1;

            img {
                box-shadow: 0px 0px 6px 2px #888;
                border-radius: 50%;
                width: 100%;
                height: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
            }
        }

        .Name {
            width: 245px;
            align-self: center;

            .Riot-Name {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 5px;
            }

            .Riot-Level {
                font-size: 13px;
            }
        }
    }

    

    


}