.Search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    
    .Search-Input{
        padding: 0 8px;
        background-color: #fff;
        border-radius: 100px;
        display: flex;
        align-items: center;

        .Search-Icon {
            width: 40px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #1e3050;
        }
        input{
            width: 300px;
            border-radius: 5px;
            outline: none;
            border: none;
            font-size: 15px;
            font-weight: 300;
        }

        .Submit{
            padding: 10px;
            button{
                border: none;
                outline: none;
                background-color: transparent;
                font-weight: 700;
                font-size: 16px;
                color: var(--main-color);
                &:hover{
                    cursor: pointer;
                    color: var(--main-color-hover);
                }
            }
        }
    }

    
}