.Modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #59595980;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        border-radius: 50%;
        cursor: pointer;
    }
}